<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    :header="`PAGO CLIENTE: ${data_pago.cliente_nombre}`"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12" v-if="cargandoComponente">
      <div v-if="permitirPago">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span><strong>CÓD.: </strong>{{ data_pago.venta_id }}</span>
          </div>
          <div class="field col-12 md:col-4">
            <span
              ><strong>TOTAL VENTA: </strong>
              {{ convertirNumeroGermanicFormat(data_pago.total_venta) }}</span
            >
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>A CUENTA:</strong></span>
            <span
              style="
                color: green;
                font-weight: bold;
                font-size: 1.2em;
                margin-left: 2px;
              "
            >
              {{ convertirNumeroGermanicFormat(suma_acuenta_total) }}
            </span>
          </div>

          <div class="field col-12 md:col-3">
            <span><strong>SALDO:</strong></span>
            <span
              v-if="
                data_pago.saldo - a_cuenta_efectivo - a_cuenta_metodo_pago > 0
              "
              style="
                color: red;
                font-weight: bold;
                font-size: 1.2em;
                margin-left: 2px;
              "
              >{{
                convertirNumeroGermanicFormat(
                  data_pago.saldo - a_cuenta_efectivo - a_cuenta_metodo_pago
                )
              }}</span
            >
            <span
              v-else
              style="
                color: green;
                font-weight: bold;
                font-size: 1.2em;
                margin-left: 2px;
              "
              >0.00</span
            >
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <span><strong>M&Eacute;TODO DE PAGO:</strong></span>
            <Dropdown
              id="metodo_pago"
              v-model="metodo_pagoSelect"
              :options="metodo_pagos"
              optionLabel="nombre"
              class="w-full"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
            <span><strong>MONTO:</strong></span>
            <InputNumber
              v-model="a_cuenta_metodo_pago"
              :minFractionDigits="2"
              mode="decimal"
              :inputClass="'text-right'"
              :min="0"
              :max="parseFloat(data_pago.saldo - a_cuenta_efectivo)"
            />
          </div>
          <div class="field col-12 md:col-4">
            <span><strong>EFECTIVO: </strong></span>
            <InputNumber
              v-model="a_cuenta_efectivo"
              :minFractionDigits="2"
              mode="decimal"
              :inputClass="'text-right'"
              :min="0"
              :max="parseFloat(data_pago.saldo - a_cuenta_metodo_pago)"
            />
          </div>
          <div class="field col-12 md:col-12">
            <span><strong>OBSERVACI&Oacute;N:</strong></span>
            <Textarea
              v-model="observacion"
              placeholder="observacion"
              autoResize
              rows="1"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <Message class="col-12" :severity="'info'" :closable="false">
          <div class="flex align-items-center justify-content-center">
            <span> PARA REALIZAR PAGOS DEBE ABRIR LA CAJA </span>
            <Button
              label="APERTURAR CAJA"
              class="p-button-success p-button-lg ml-3"
              icon="pi pi-lock-open"
              v-tooltip.top="'Abrir Caja'"
              @click="this.$router.push({ name: 'caja' })"
            />
          </div>
        </Message>
      </div>
    </div>
    <div v-else>
      <div class="grid">
        <h3 class="col-12">Verificando caja ...</h3>
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="col-12"
        />
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong> CAMPOS REQUERIDOS: </strong
          ><span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarPagoClientes"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import PagoClienteService from "@/service/PagoClienteService.js";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import CajaService from "@/service/CajaService";

export default {
  name: "PagoCliente",
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pago_cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_pago: this.pago_cliente,
      enviado: false,
      errors: {},
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      a_cuenta_metodo_pago: null,
      a_cuenta_efectivo: null,
      saldo: 0,
      permitirPago: false,
      cargandoComponente: false,
      observacion: "",
    };
  },
  pagoClienteService: null,
  metodoPagoService: null,
  cajaService: null,
  created() {
    this.pagoClienteService = new PagoClienteService();
    this.metodoPagoService = new MetodoPagoService();
    this.cajaService = new CajaService();
  },
  mounted() {
    this.getMetodoPagos();
    this.verificarCajaAbierta();
  },
  computed: {
    suma_acuenta_total() {
      return (
        parseFloat(this.data_pago.a_cuenta_efectivo) +
        parseFloat(this.data_pago.a_cuenta_metodo_pago)
      );
    },
  },
  methods: {
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirPago = true;
        } else {
          this.permitirPago = false;
        }
        this.cargandoComponente = true;
      });
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    guardarPagoClientes() {
      this.enviado = true;
      // Preparo los datos para enviar
      let datos = {
        id: this.data_pago.id,
        metodo_pago_id: this.metodo_pagoSelect,
        a_cuenta_metodo_pago: this.a_cuenta_metodo_pago
          ? this.a_cuenta_metodo_pago
          : 0,
        a_cuenta_efectivo: this.a_cuenta_efectivo ? this.a_cuenta_efectivo : 0,
        venta_id: this.data_pago.venta_id,
        observacion: this.observacion,
        cliente_nombre: this.data_pago.cliente_nombre,
      };

      // VALIDO LOS MONTOS INTRODUCIDOS QUE SEAN MAYORES A CERO
      if (datos.a_cuenta_metodo_pago <= 0 && datos.a_cuenta_efectivo <= 0) {
        this.enviado = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un monto mayor a cero!",
          life: 3000,
        });
        return;
      }
      this.pagoClienteService.actualizoPagoCliente(datos).then((response) => {
        if (response.success == false) {
          this.enviado = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Pago realizado con éxito!",
            life: 3000,
          });
          this.imprimirPago(response.data_detalle);
          this.enviado = false;
          this.$emit("actualizarListado");
          this.$emit("closeModal");
          this.closeModal();
        }
      });
    },
    imprimirPago(detalle) {
      let datos = {
        id: detalle.id,
        nombre_usuario: detalle.nombre_usuario,
        nro_cuota: detalle.nro_cuota,
        fecha_pago: detalle.fecha_pago,
        observacion: detalle.observacion,
        a_cuenta_efectivo: detalle.a_cuenta_efectivo,
        a_cuenta_metodo_pago: detalle.a_cuenta_metodo_pago,
        nombre_metodo_pago: detalle.nombre_metodo_pago,
        venta_id: detalle.venta_id,
        sucursal_id: detalle.sucursal_id || null,
        cliente_nombre: detalle.cliente_nombre,
        total_venta: detalle.total_venta,
        total_pagado: detalle.total_pagado,
        saldo: detalle.saldo,
      };
      this.imprimiendoPago = true;
      this.pagoClienteService
        .imprimirPago(datos)
        .then(() => {
          this.imprimiendoPago = false;
        })
        .catch(() => {
          this.imprimiendoPago = false;
        });
    },
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
      this.metodo_pagoSelect = 1;
      this.a_cuenta_metodo_pago = null;
      this.a_cuenta_efectivo = null;
      this.saldo = 0;
      this.observacion = "";
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    pago_cliente(val) {
      this.data_pago = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
